import forEach from '../dom/forEach';
import addClass from '../dom/addClass';
import removeClass from '../dom/removeClass';
import select from '../dom/select';
import getPrevSibling from '../dom/getPrevSibling';

export default nav => {
  const navSelect = nav.querySelector('[data-js-pl-filter-nav-select]');
  const itemSelector = '[data-js-pl-filter-nav-item]';
  const itemList = select(itemSelector, nav);
  const categoryList = select('[data-js-pl-filter-nav-category]', nav);
  const filterHiddenClass = 'pl-filter-nav__item--hidden';
  const noResultMessageHiddenClass = 'pl-filter-nav__no-result-message--hidden';
  const noResultMessageSelector = '[data-js-pl-filter-nav-no-result-message]';

  navSelect.addEventListener('keyup', event => {
    const searchPattern = new RegExp(event.target.value, 'i');

    // show/hide filtered items
    forEach(itemList, (index, item) => {
      if (searchPattern.test(item.getAttribute('data-js-pl-filter-nav-item'))) {
        removeClass(item, filterHiddenClass);
      } else {
        addClass(item, filterHiddenClass);
      }
    });

    // show message if there is no entry left in subfilter-nav
    forEach(categoryList, (index, category) => {
      const message = getPrevSibling(category, noResultMessageSelector);
      if (category.querySelectorAll(`${itemSelector}:not(.${filterHiddenClass})`).length) {
        addClass(message, noResultMessageHiddenClass);
      } else {
        removeClass(message, noResultMessageHiddenClass);
      }
    });
  });
};
