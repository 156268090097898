import navFilter from './filterNav';
import toggleClass from '../dom/toggleClass';

// Filter Pattern-Library navigation
const patternLibraryFilterNav = document.querySelector('[data-js-pl-filter-nav]');

if (patternLibraryFilterNav) navFilter(patternLibraryFilterNav);

// check if Ctrl+Alt+g is pressed to activate susy-svg-grid
document.addEventListener('keydown', event => {
  if (event.code === 'KeyG' && event.altKey && event.ctrlKey) {
    toggleClass(document.body, 'sg-debug-grid');
  }
});
