/**
 * Gets the previous element by selector
 * @param {Object} el Element the sibling is searched for
 * @param {Object} selectorString Selector the sibling should match to
 * @returns {Object} Previous element matchig the selector
 */
export default (el, selectorString) => {
  const prevElementCandidate = el.previousElementSibling;
  let prevElement;

  if (prevElementCandidate && prevElementCandidate.matches(selectorString)) {
    prevElement = prevElementCandidate;
  }

  return prevElement;
};
